import { useApiConfig } from '@upvio/next'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { CurrentUserQuery } from '../types/graphql'
import { LSK_USER } from '../utils/constants'

export type CurrentUser = CurrentUserQuery['currentUser']

interface AuthStoreState {
  accessToken?: string | null
  auth0Token?: string | null
  currentUser?: CurrentUser | null
}

interface AuthData {
  accessToken: string
  currentUser: CurrentUser
}

interface AuthStoreActions {
  setAuthData: (data: AuthData) => void
  clear: () => void

  /**
   * Clear the underlying storage without triggering a store update.
   * This is used to logout without the auth wrapper reloading the page and
   * stopping the redirect back to auth0.
   */
  clearStorage: () => void
}

export const useAuthStore = create<AuthStoreState & AuthStoreActions>()(
  persist(
    (set) => ({
      currentUser: undefined,
      accessToken: undefined,
      setAuthData: ({ accessToken, currentUser }) => {
        set({ accessToken, currentUser })
      },
      clear: () => {
        set({
          accessToken: null,
          auth0Token: null,
          currentUser: null,
        })
      },
      clearStorage: () => {
        localStorage.removeItem(LSK_USER)
      },
    }),
    {
      name: LSK_USER,
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
